import React, { useRef } from 'react'
import styled from 'styled-components'
import RichText from '../RichText'
import Button from '../Button'
import SmallHeading from '../SmallHeading'

import {
  font,
  colors,
  mobileVW,
  desktopVW,
  letterSpacing,
  desktopBreakpoint,
} from '../../styles'

import AnimatedElement from '../animation/AnimatedElement'

const StyledIntroduction = styled.section`
  text-align: center;
  color: ${colors.white};
  margin: ${mobileVW(10)} ${mobileVW(30)} 0;

  ${desktopBreakpoint} {
    margin: ${desktopVW(200)} ${desktopVW(0)} 0;
  }
`

const Content = styled.p`
  font-family: ${font.serif};
  color: ${colors.white};
  font-size: ${mobileVW(17)};
  line-height: ${mobileVW(26)};
  letter-spacing: ${letterSpacing(6)};
  margin: ${mobileVW(20)} auto;

  ${desktopBreakpoint} {
    font-size: ${desktopVW(70)};
    line-height: ${desktopVW(97)};
    max-width: 84vw;
    margin: ${desktopVW(50)} auto;
  }
`

const Introduction = ({ heading, text, buttonText }) => {
  const ref = useRef()

  return (
    <StyledIntroduction ref={ref}>
      <SmallHeading>{heading}</SmallHeading>
      <Content>
        <RichText json={text} />
      </Content>

      <Button to='/story'>{buttonText}</Button>
    </StyledIntroduction>
  )
}

export default Introduction
