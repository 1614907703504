import React from 'react'
import styled from 'styled-components'
import SmallHeading from '../SmallHeading'
import Button from '../Button'
import NewsThumbnail from '../NewsThumbnail'
import NewsWrapper from '../NewsWrapper'
import { mobileVW, desktopVW, desktopBreakpoint } from '../../styles'

const StyledNews = styled.section`
  text-align: center;
  margin: ${mobileVW(80)} ${mobileVW(40)} 0 ${mobileVW(40)};

  ${desktopBreakpoint} {
    margin: ${desktopVW(200)} 0 0;
    padding: 0;
  }
`

const News = ({ heading, buttonText, news }) => (
  <StyledNews>
    <SmallHeading>{heading}</SmallHeading>
    <NewsWrapper>
      {news.map(({ title, link, image }) => (
        <NewsThumbnail title={title} link={link} image={image} />
      ))}
    </NewsWrapper>
    <Button to='/news'>{buttonText}</Button>
  </StyledNews>
)

export default News
