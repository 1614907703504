import React from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/home/Hero'
import Introduction from '../components/home/Introduction'
import Investments from '../components/home/Investments'
import NewsSection from '../components/home/NewsSection'
import Subscribe from '../components/Subscribe'
import SEO from '../components/SEO'

const Home = ({
  data: {
    contentfulNews: { nodes: newsItems },
    contentfulHomePage: {
      heroHeading,
      heroImages,
      introductionHeading,
      introductionText,
      introductionButtonText,
      investmentHeading,
      investmentButtonText,
      investments,
      newsHeading,
      newsButtonText,
    },
  },
}) => (
  <>
    <SEO title={`HOME | ASK PHILL VENTURES`} />
    <Hero images={heroImages} heading={heroHeading.json} />
    <Introduction
      heading={introductionHeading}
      text={introductionText.json}
      buttonText={introductionButtonText}
    />

    <Investments
      heading={investmentHeading}
      buttonText={investmentButtonText}
      investments={investments}
    />
    <NewsSection
      heading={newsHeading}
      buttonText={newsButtonText}
      news={newsItems}
    />
    <Subscribe />
  </>
)

export const PageQuery = graphql`
  query Home($id: String!) {
    contentfulHomePage(id: { eq: $id }) {
      heroHeading {
        json
      }
      heroImages {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      introductionHeading
      introductionText {
        json
      }
      introductionButtonText
      investmentHeading
      investmentButtonText
      investments {
        image {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        name
        investmentDetail
        investmentDetailTwo
        websiteLink
      }
      newsHeading
      newsButtonText
    }
    contentfulNews: allContentfulNews(limit: 3) {
      nodes {
        title
        link
        image {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default Home
