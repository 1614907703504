import React from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'
import SmallHeading from '../SmallHeading'
import Button from '../Button'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

import {
  mobileVW,
  desktopVW,
  font,
  colors,
  desktopBreakpoint,
  quad,
} from '../../styles'

const StyledInvestments = styled.section`
  text-align: center;
  margin-top: ${mobileVW(80)};
  overflow: hidden;

  ${desktopBreakpoint} {
    margin-top: ${desktopVW(200)};
  }
`
const SlideShow = styled.div`
  height: 90vw;
  margin-top: ${mobileVW(40)};

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  ${desktopBreakpoint} {
    height: 62vw;
    margin-top: ${desktopVW(80)};

    .swiper-button-prev,
    .swiper-button-next {
      height: 100%;
      top: 0;
      width: ${desktopVW(350)};

      &::after {
        display: none;
      }
    }
  }
`

const ImageWrapper = styled.div`
  z-index: -1;
  position: relative;
  width: 90vw;
  //opacity: ${props => (props.visible ? '1' : '0')};
  transition: 0.2s ease;


  ${desktopBreakpoint} {
    position: relative;
    width: 40vw;
    display: block;
    transition: 0.2s ease;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
`
const FirstDetail = styled.div`
  position: absolute;
  top: ${desktopVW(40)};
  left: ${desktopVW(40)};
  z-index: 1;
  display: none;

  ${desktopBreakpoint} {
    display: block;
  }
`
const SecondDetail = styled.div`
  position: absolute;
  bottom: ${desktopVW(40)};
  right: ${desktopVW(40)};
  z-index: 1;
  display: none;

  ${desktopBreakpoint} {
    display: block;
  }
`

const Name = styled.div`
  text-align: center;
  display: flex;
  font-size: ${desktopVW(150)};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  //opacity: ${props => (props.visible ? '1' : '0.2')};
  span{
    margin-top: 2vw;
    font-family: ${font.extended};
    font-size: ${desktopVW(20)};

  }
`

const Slide = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 52vw;
  ${ImageWrapper} {
    transform: scale(0.7) rotate(20deg);
    opacity: 0;
  }

  &.swiper-slide-next {
    ${ImageWrapper} {
      opacity: 0;
      transform: scale(0.7) rotate(20deg);
    }
    ${Name} {
      opacity: 0.2;
      span {
        opacity: 0;
        transform: translateY(2vw) rotate(20deg);
      }
    }
  }

  &.swiper-slide-prev {
    ${ImageWrapper} {
      opacity: 0;
      transform: scale(0.7) rotate(-20deg);
    }
    ${Name} {
      opacity: 0.2;
      span {
        opacity: 0;
        transform: translateY(2vw) rotate(-20deg);
      }
    }
  }

  &.swiper-slide-active {
    ${ImageWrapper} {
      opacity: 1;
      transform: none;
      transition: opacity 0.8s ${quad.inOut}, transform 0.8s ${quad.inOut};
    }
    ${Name} {
      opacity: 1;
      span {
        opacity: 1;
        transform: none;
        transition: opacity 0.8s ${quad.inOut}, transform 0.8s ${quad.inOut};
      }
    }
  }
`

const Investments = ({ heading, investments, buttonText }) => {
  const params = {
    effect: 'slide',
    speed: 1000,
    grabCursor: true,
    //loop: true,
    resistanceRatio: 0.001,
    initialSlide: 1,
    centeredSlides: true,
    slidesPerView: 1.7,
    // autoplay: {
    //   delay: 0,
    // },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }

  return (
    <StyledInvestments>
      <SmallHeading>{heading}</SmallHeading>
      <SlideShow>
        <Swiper {...params}>
          {investments.map(
            (
              {
                name,
                websiteLink,
                image,
                investmentDetailTwo,
                investmentDetail,
              },
              i,
            ) => (
              <Slide key={i}>
                <ImageWrapper>
                  <Overlay />
                  <Image
                    loading='eager'
                    fluid={image.fluid}
                    backgroundColor={colors.backgroundImage}
                    // style={'position: absolute;'}
                  />
                  <FirstDetail>{investmentDetail}</FirstDetail>
                  <SecondDetail>{investmentDetailTwo}</SecondDetail>
                </ImageWrapper>

                <Name href={websiteLink} target='_blank'>
                  <h1>{name}</h1>
                  <span>0{i + 1}</span>
                </Name>
              </Slide>
            ),
          )}
        </Swiper>
      </SlideShow>
      <Button to='/contact'>{buttonText}</Button>
    </StyledInvestments>
  )
}
export default Investments
