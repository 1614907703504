import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import Button from '../Button'
import RichText from '../RichText'
import AnimatedElement from '../animation/AnimatedElement'
import { useRecoilValue } from 'recoil'
import { startPageAnimation } from '../../atoms'

import {
  mobileVW,
  desktopVW,
  font,
  quad,
  expo,
  colors,
  letterSpacing,
  desktopBreakpoint,
} from '../../styles'

const pagetransDelay = 0
const pageAnimationDuration = 0.9

const StyledHero = styled.section`
  position: relative;
  height: ${mobileVW(550)};
  width: 100vw;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;

  ${desktopBreakpoint} {
    margin-top: ${desktopVW(150)};
    min-height: ${desktopVW(500)};
    height: ${desktopVW(850)};
    max-height: ${desktopVW(1000)};
  }
`

const Heading = styled.h1`
  font-size: ${mobileVW(32)};
  line-height: ${mobileVW(40)};
  letter-spacing: ${letterSpacing(6)};
  margin: 0 ${mobileVW(40)} ${mobileVW(40)};

  ${desktopBreakpoint} {
    max-width: 85vw;
    font-size: ${desktopVW(70)};
    line-height: ${desktopVW(88)};
    margin: 0 0 20vw;
    transform: ${props =>
      props.visible ? 'scale(1) translateY(0%)' : 'scale(.9) translateY(0%)'};
    opacity: ${props => (props.visible ? '1' : '0')};
    transition: transform ${pageAnimationDuration}s ${quad.inOut},
      opacity ${pageAnimationDuration}s ${quad.inOut};
    transition-delay: ${props => (props.visible ? pagetransDelay : 9)} s;
  }
`

const Images = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 60vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${desktopBreakpoint} {
    width: 40vw;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
`

const StyledImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: flex;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 2s ${expo.out};
  transition-delay: ${props => (props.visible ? pagetransDelay : 9)} s;
`

const StyledIframe = styled.div`
  height: 100vh;
  width: 100%;
  top: 0;
  position: fixed;

  iframe {
    height: 100vh;
  }
`

const Hero = ({ images, heading }) => {
  const [currentImage, setCurrentImage] = useState(images[0].fluid)
  const [index, setIndex] = useState(0)
  const [distance, setDistance] = useState(0)
  const appear = useRecoilValue(startPageAnimation)
  let mouse = null

  const handleMouseEnter = event => {
    const { clientX, clientY } = event
    mouse = { x: clientX, y: clientY, distance: 0 }
  }

  const handleMouseMove = event => {
    const calculateDistance = (x1, y1, x2, y2) =>
      Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2))
    const { clientX, clientY } = event

    // const { x, y, distance } = mouse

    // mouse = {
    //   x: clientX,
    //   y: clientY,
    //   distance: 0 + calculateDistance(0, 0, clientX, clientY),
    // }
    // mouse = {
    //   x: clientX,
    //   y: clientY,
    //   distance: calculateDistance(
    //     mouse.x ? mouse.x : 0,
    //     mouse.y ? mouse.y : 0,
    //     clientX,
    //     clientY,
    //   ),
    // }
    setDistance(
      calculateDistance(
        mouse.x ? mouse.x : 0,
        mouse.y ? mouse.y : 0,
        clientX,
        clientY,
      ),
    )
    console.log(clientY - clientX)
    if (distance > 900) {
      console.log('index check', images.length, index)
      if (index === images.length - 1) {
        setIndex(0)
      } else {
        setIndex(index + 1)
      }
      // console.log(index >= images.length - 1 ? 'go' : 'nop')
      // index >= images.length - 1 ? setIndex(index + 1) : setIndex(0)
    }
  }

  const handleMouseLeave = () => {}

  useEffect(() => {
    console.log(index)
    setCurrentImage(images[index].fluid)
  }, [index])

  useEffect(() => {
    // console.log(distance)
  }, [distance])

  if (mouse) {
    if (distance > 512) {
      console.log(index, 'index')
      if (index === images.length - 1) {
        setIndex(0)
      } else {
        setIndex(index + 1)
      }
      setDistance = 0
    }
  }

  return (
    <StyledHero
    // onMouseEnter={handleMouseEnter}
    // onMouseMove={handleMouseMove}
    // onMouseLeave={handleMouseLeave}
    >
      <Images>
        <Overlay />

        <ImageWrapper>
          <StyledImage
            visible={appear}
            backgroundColor={colors.backgroundImage}
            fluid={currentImage}
          />
        </ImageWrapper>
      </Images>

      <Heading visible={appear}>
        <RichText json={heading} />
      </Heading>
    </StyledHero>
  )
}

export default Hero
